import React, { Component, Suspense, useEffect, useState } from 'react'
import { Route, Routes, json } from 'react-router-dom'
import './scss/style.scss'
import { RequireAuth } from 'react-auth-kit';
import { useLocation, useNavigate } from 'react-router-dom';


const loading = (<div className="pt-3 text-center"> <div className="sk-spinner sk-spinner-pulse"></div></div>)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let data, data2;

    const fetchData = async () => {

      try {
        const roles = localStorage.getItem('userRoles');
        const parsedRoles = JSON.parse(roles);
        data = parsedRoles[0]['routes'];
        data2 = parsedRoles[0]['name'];

        const isDynamicRoute = (route) => {
          // Check if the route contains a numeric or alphanumeric segment
          return /\/:\w+/.test(route);
        };

        const sanitizeRoute = (route) => {
          // Replace numeric segments with placeholders
          return route.replace(/\/\d+/g, '/:id');
        };

        const checkRouteAvailable = (routeToCheck) => {
          // Check if the route to check matches any predefined routes
          return data.some(predefinedRoute => {
            if (isDynamicRoute(predefinedRoute.path)) {
              // Sanitize both routes for comparison if the predefined route is dynamic
              const sanitizedPredefinedRoute = sanitizeRoute(predefinedRoute.path);
              const sanitizedRouteToCheck = sanitizeRoute(routeToCheck);
              return sanitizedPredefinedRoute === sanitizedRouteToCheck;
            } else {
              // Direct comparison for static routes
              return predefinedRoute.path === routeToCheck;
            }
          });
        };

        const allowedOrNot = checkRouteAvailable(location.pathname);

       // console.log(`Allowed Or Not: ${location.pathname} : `, allowedOrNot)

        if (data2 !== 'super-admin') {
          if (location.pathname === "/dashboard" || location.pathname === "/login" || location.pathname === "/" || location.pathname === "/profile") {
            return;
          } else if (!allowedOrNot) {
            navigate('/404');
          } else {
            return;
          }
        }

      } catch (error) {
        console.error("Error in App.js: ", error);
      }
    };

    fetchData();

  }, [location.pathname, navigate]);


  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route exact path="*" element={<RequireAuth loginPath="/login" >
          <DefaultLayout />
        </RequireAuth>} />
      </Routes>
    </Suspense>
  )
}

export default App
